import React from 'react';
import { Link } from 'react-router-dom';

function Footer() {
  return (
    <footer className="tc-footer-style1">
      <div className="container">
        <div className="top-content section-padding">
          <div className="row gx-0">
            <div className="col-lg-4">
              <div className="info-side">
                <div className="text fsz-24 color-333 lh-3 fw-600">
                  Mimarlığın hayatları şekillendirme ve toplulukları yükseltme gücüne sahip olduğuna inanıyoruz. Şirketimizin felsefesi, yenilikçilik, sürdürülebilirlik ve zamansız estetiğe duyduğumuz tutkudur.
                </div>
                <div className="foot-social mt-50">
                  <a href="#">
                    <i className="fab fa-x-twitter"></i>
                  </a>
                  <a href="#">
                    <i className="fab fa-facebook-f"></i>
                  </a>
                  <Link target="_blank" to={"https://www.instagram.com/betulakarchitecture/"}>
                    <i className="fab fa-instagram"></i>
                  </Link>
                  <Link target="_blank" to="https://www.linkedin.com/in/bet%C3%BCl-ak-133385235/">
                    <i className="fab fa-linkedin-in"></i>
                  </Link>
                  <a href="#">
                    <i className="fab fa-youtube"></i>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-3 offset-lg-2">
              <div className="branch-card">
                <h5 className="mb-20 mt-5 mt-lg-0 fw-600"> Bursa, TR </h5>
                <ul className="footer-links">
                  <li>
                    <Link to="https://www.google.com/maps?q=Fatih+Sk.+35-19,+Fethiye,+16140+Ni̇lüfer/Bursa" target="_blank">
                      Fatih Sk. 35-19, Fethiye, 16140 Ni̇lüfer/Bursa</Link>
                  </li>
                  <li>
                    <Link to={"mailto:info@betulakarchitecture.com.tr"}> info@betulakarchitecture.com.tr </Link>
                  </li>
                  <li>
                    <Link to={"tel:+905362241446"}> (530) 739 99 16 </Link>
                  </li>
                </ul>
              </div>
              <div className="branch-card">
                <h5 className="mb-20 mt-5 fw-600"> Hizmet Alanlarımız </h5>
                <ul className="footer-links">
                  <li>
                    <a href="#"> 3D Görselleştirme Mimarisi </a>
                  </li>
                  <li>
                    <a href="#"> İç Mekan Mimarisi </a>
                  </li>
                  <li>
                    <a href="#"> Sürdürülebilir Tasarım Mimarisi </a>
                  </li>
                  <li>
                    <a href="#"> Proje Yönetimi ve Danışmanlık </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="branch-card">
                <h5 className="mb-20 mt-5 mt-lg-0 fw-600"> İstanbul, TR </h5>
                <ul className="footer-links">
                  <li>
                    <Link href="#"> Huzur, Azerbaycan Cd. No:4, 34415 Sarıyer/İstanbul</Link>
                  </li>
                  <li>
                    <Link to={"mailto:info@betulakarchitecture.com.tr"}> info@betulakarchitecture.com.tr </Link>
                  </li>
                  <li>
                    <Link to={"tel:+905362241446"}> (530) 739 99 16 </Link>
                  </li>
                </ul>
              </div>
              <div className="branch-card">
                <h5 className="mb-20 mt-5 fw-600"> Yardımcı Bağlantılar </h5>
                <ul className="footer-links">
                  <li>
                    <a href="#"> Şartlar ve Koşullar </a>
                  </li>
                  <li>
                    <a href="#"> Ortaklık Politikası </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="foot">
          <div className="row">
            <div className="col-lg-6">
              <p className="fsz-13">© 2024 betulakarchitecture. Tüm hakları saklıdır</p>
            </div>
            <div className="col-lg-6">
              <div className="foot-links mt-4 mt-lg-0">
                <a href="#"> Ana Sayfa </a>
                <a href="#"> Stüdyo </a>
                <a href="#"> Projelerim </a>
                <a href="#"> Blog </a>
                <a href="#"> İletişim </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
